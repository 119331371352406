import { Injectable } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import { environment } from 'src/environments/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private loader: NgxUiLoaderService, private toastr: ToastrService) { }
  uploadurl = `${environment.bucketurl}`;
  // FOLDER:any;/
  Bucketname = `${environment.bucketname}`;
  bucket = new S3(
    {
      accessKeyId: 'AKIAZIVAFNGO4NA5LYXG',
      secretAccessKey: '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',
      region: 'us-east-2'
    }
  );

  async uploadFile(fileToUpload: File) {
    console.log("🚀 ~ UploadService ~ uploadFile ~ fileToUpload:", fileToUpload)
    try {
      const params = {
        Bucket: this.Bucketname,
        Key: fileToUpload.name,
        Body: fileToUpload,
        ACL: 'public-read'
      };

      return new Promise((resolve, reject) => {
        // this.loader.start();
        this.bucket.upload(params, function (err: any, data: any) {
          if (err) {
            reject(err);
            return err;
          } else {
            resolve(data?.Location);
            return data?.Location;
          }
        });
        // this.loader.stop();
      });
    } catch (err: any) {
      this.toastr.error("An error was occured during image uploading")
      console.error(err.message);
      this.loader.stop();
    }
    finally {
    }
  }
}
