import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { CallbackComponent } from './callback/callback.component';
import { RouteConstant } from './core/_constants/route.constant';
import { AuthGuard } from './core/_guards/auth.guard';
import { JoinMeetingComponent } from './pages/coaching/live-stream/join-meeting/join-meeting.component';

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import(`./onboarding/onboarding.module`).then(m => m.OnboardingModule)
  },
  {
    path: "",
    loadChildren: () => import(`./theme/theme.module`).then(m => m.ThemeModule)
  },
  {
    path: `${RouteConstant.coaching.path + '/' + RouteConstant.liveStream.path + '/' + RouteConstant.joinMeeting.path}`,
    component: JoinMeetingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: `callback`,
    component: CallbackComponent,
  },
  {
    path: "**",
    redirectTo: RouteConstant.login.path
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules,  scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
