import { Component } from '@angular/core';
import { MessagingService } from './core/_services/messaging.service';
import { AngularFireMessaging } from '@angular/fire/messaging';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'client-admin-panel';
  message: any;
  constructor(private messagingService: MessagingService, private angularFireMessaging: AngularFireMessaging) {
    this.messagingService.getPermission();
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;
  
    // You can also handle notifications directly in the constructor if needed
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        console.log(payload,'payload')
      }
    );
  }
  ngOnInit(){
  }
  // ngOnDestroy() {
  //   this.messagingService.ngOnDestroy();
  // }
}
