import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, take } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class MessagingService {
  private ngUnsubscribe = new Subject();
  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private db: AngularFireDatabase,
    private toastr: ToastrService,
    private afAuth: AngularFireAuth
  ) {
    this.angularFireMessaging.messages.subscribe(
      (_messaging) => {
        _messaging['onMessage'] = _messaging['onMessage'].bind(_messaging);
        _messaging['onTokenRefresh'] = _messaging['onTokenRefresh'].bind(_messaging);
      //   _messaging['onBackgroundMessage'] = _messaging['onBackgroundMessage'].bind(_messaging);
      //   console.log(_messaging);
      console.log('Received push notification:', _messaging);
      }
    )
  }
  
token:any;
  private updateToken(token) {
    this.afAuth.authState.pipe(take(1)).subscribe((user) => {
      if (!user) return;
      const data = { [user.uid]: token };
      this.db.object('fcmTokens/').update(data);
    });
  }

  getPermission() {
    this.angularFireMessaging.requestPermission
      .pipe(take(1))
      .subscribe((x) => {
        console.log(x,'data')
        this.angularFireMessaging.getToken.pipe(take(1)).subscribe((token) => {
          this.updateToken(token);
          this.token=token;
          console.log(this.token)
        });
      });
  }

  receiveMessage() {
    console.log('receive')
    this.angularFireMessaging.messages.subscribe((payload) => {
      console.log(payload, 'payload');
      this.currentMessage.next(payload);
    },(error) => {
      console.error('Error receiving message:', error);
    }
);
  }
  getToken(){
    return this.token
    }
  ngOnDestroy() {
    this.ngUnsubscribe.next(undefined);
    this.ngUnsubscribe.complete();
  }
}
