// import { environment } from "src/environments/environment";

import * as moment from "moment";

export class AppConst {
  // encryption key
  public static readonly ENC_KEY = "!ServiceGalaxy";
  public static readonly ENCRYPITION_KEY = 'sdfgencrypt098978878hjjkiuyo7890'

  // API KEY
  public static readonly API_KEY = "1234";

  // numberValidation
  public static readonly NUMBER_WITH_TWO_DECIMAL =
    /^\s*(?=.*[1-9])\d*(?:(\.|\,)\d{1,2})?\s*$/;
  public static readonly NUMBER_WITH_ZERO_AND_TWO_DECIMAL =
    /^\s*(?=.*[0-9])\d*(?:(\.|\,)\d{1,2})?\s*$/;
  //emailValidation
  public static readonly EMAIL_PATTERN = `^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$`;

  public static readonly DATE_FORMAT = `dd MMM YYYY`;
  public static readonly TIME_FORMAT = `shortTime`;
  public static readonly PASSWORD_PATTERN = `(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&])[A-Za-z0-9\d@$!%*?&].{7,19}`;

  public static readonly ZIPCODE_PATTERN = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

  // API BASE URL
  // public static readonly API_BASE_URL = environment.API_BASE_URL;

  public static readonly APPLICATION_BASE_URL = window.location.origin;

  // server HTTP header status code
  public static readonly HTTP_STATUS: HttpStatus = {
    OK: 200,
    SERVER_INTERNAL_ERROR: 500,
    FORBIDDEN: 401,
    IFNOTOK: 201,
    BAD_REQUEST: 400,
    NOT_FOUND: 404,
    NO_CONTENT: 204,
  };
  public static readonly SERVER_CODES = {
    VALIDATION_ERROR: "E103",
  };
  public static readonly ERROR_MESSAGES = {
    IMAGE_DIMENSIONS: "Image should have min dimensions {{width}}X{{height}}",
    SERVER_ERROR: "Something went wrong",
  };
  public static STORAGE_KEYS = {
    USER_TOKEN: "USER_TOKEN",
    USER_ID: "USER_ID",
    USER_DETAILS: "USER_DETAILS",
    USER_TYPE: "userType",
  };
  public static MAXLENGTH = {
    clientName: 35,
    companyName: 35,
    phoneNumber: 10,
    pinCode: 10,
    customerName: 35,
    name: 35,
    script: 30,
    serviceName: 25,
    descriptionTitle: 25,
    description: 250,
    itemDesc: 2000,
    itemName: 40,
    price: 9,
    title: 30,
    vendor: 50,
  };

  public static default = {
    msg: `Welcome To The Service Galaxy Family.`,
  };

  public static privileges = [
    {
      name: "Dashboard",
      id: "dashboard",
      subPrivileges: [
        {
          name: "Reporting",
          id: "dashboardReporting",
        },
        {
          name: "Notifications",
          id: "dashboardNotification",
        },
        {
          name: "Tasks",
          id: "dashboardTask",
        },
        {
          name: "My events",
          id: "dashboardMyEvent",
        },
        {
          name: "Events",
          id: "dashboardEvent",
        },
        {
          name: "Opportunities",
          id: "dashboardOpportunity",
        },
        {
          name: "Jobs",
          id: "dashboardJob",
        },
        {
          name: "Customers",
          id: "dashboardCustomer",
        },
        {
          name: "Employees",
          id: "dashboardEmployee",
        },
      ],
    },
    {
      name: "Opportunities",
      id: "opportunities",
      subPrivileges: [
        {
          name: "Opportunity",
          id: "opportunity",
        },
      ],
    },
    {
      name: "Jobs",
      id: "job",
      subPrivileges: [
        {
          name: "Job",
          id: "job",
        },
      ],
    },
    {
      name: "Invoices",
      id: "invoice",
      subPrivileges: [
        {
          name: "Invoice",
          id: "invoice",
        },
      ],
    },
    {
      name: "Customers",
      id: "customer",
      subPrivileges: [
        {
          name: "Customer",
          id: "customer",
        },
      ],
    },
    {
      name: "Employees",
      id: "employees",
      subPrivileges: [
        {
          name: "Employee",
          id: "employee",
        },
      ],
    },
    {
      name: "Coaching",
      id: "coaching",
      subPrivileges: [
        {
          name: "Live Stream",
          id: "liveStream",
        },
        {
          name: "Client Feed",
          id: "clientFeed",
        },
        {
          name: "Feed",
          id: "feed",
        },
        {
          name: "Library",
          id: "library",
        },
      ],
    },
    {
      name: "Planning",
      id: "planning",
      subPrivileges: [
        {
          name: "AIMR",
          id: "aimr",
        },
        {
          name: "Schedule",
          id: "schedule",
        },
        {
          name: "Calendar",
          id: "calendar",
        },
        {
          name: "Dispatch",
          id: "dispatch",
        },
      ],
    },
    {
      name: "Reporting",
      id: "reporting",
      subPrivileges: [
        {
          name: "Report",
          id: "report",
        },
      ],
    },
    {
      name: "Scripting",
      id: "scripting",
      subPrivileges: [
        {
          name: "Published",
          id: "published",
        },
        {
          name: "Builder",
          id: "builder",
        },
      ],
    },
    {
      name: "Roles",
      id: "roles",
      subPrivileges: [
        {
          name: "Roles",
          id: "role",
        },
      ],
    },
    {
      name: "Messages",
      id: "chat",
      subPrivileges: [
        {
          name: "Customer Chat",
          id: "customerChat",
        },
        {
          name: "Team Chat",
          id: "teamChat",
        },
      ],
    },
    {
      name: "Items",
      id: "items",
      subPrivileges: [
        {
          name: "Item",
          id: "item",
        },
      ],
    },

    {
      name: "Forms",
      id: "forms",
      subPrivileges: [
        {
          name: "Forms",
          id: "forms",
        },
      ],
    },
    {
      name: "Email Marketing",
      id: "emailMarketing",
      subPrivileges: [
        {
          name: "campaign",
          id: "campaign",
        },
        {
          name: "templates",
          id: "template",
        },
      ],
    },

    // {
    // 	name:'Forms',
    // 	id:'forms',
    // 	subPrivileges: [
    // 		{
    // 		  name: "Forms",
    // 		  id: "forms",
    // 		},
    // 	  ],
    // }
    //   {
    // 	name: 'Settings',
    // 	id: 'settings',
    // 	subPrivileges: [
    // 	  {
    // 		name:'Working Hours',
    // 		id: 'workingHour'
    // 	  },
    // 	  {
    // 		name:'Employee Distance',
    // 		id: 'employeeDistance'
    // 	  },
    // 	  {
    // 		name:'Services',
    // 		id: 'service'
    // 	  },
    // 	  {
    // 		name:'Description',
    // 		id: 'description'
    // 	  },
    // 	  {
    // 		name:'Source',
    // 		id: 'source'
    // 	  },
    // 	  {
    // 		name:'Tax',
    // 		id: 'tax'
    // 	  }
    // 	]
    //   },
  ];
}

export interface HttpStatus {
  OK: number;
  IFNOTOK: number;
  NOT_FOUND: number;
  FORBIDDEN: number;
  BAD_REQUEST: number;
  SERVER_INTERNAL_ERROR: number;
  NO_CONTENT: number;
}
export interface AdminProfile {
  firstName: String;
  lastName: String;
  email: String;
  fullName: String;
  profilePic: String;
  companyLogo: String;
}

export const APP_CONSTANT = {
  DASHBOARD: {
    dashboard: "Dashboard",
    client: "Total Client",
    customer: "Total Customers",
    employees: "Total Employees",
    clientPayMonthly: "Payments from client Monthly",
    clientPayMonthlyByCreditCard: "Payments collected monthly from Credit Card",
    totalPayment: "Total payments monthly",
  },
  SUBSCRIPTION: {
    START: {
      heading: "Start your subscription please complete the payment first",
      btnText: "Click here to make payment",
      icon: "assets/images/mark_email_read.svg",
    },
    EXPIRED: {
      heading:
        "Your subscription has expired, please purchase subscription to gain access to the platform.",
      btnText: "Click here to renew your transaction",
      icon: "assets/images/renew.svg",
    },
  },
  UNSUBSCRIBE: {
    onload: {
      heading: "Unsubscribe from Service Galaxy Emails",
      subHeading:
        "You are opting out of receiving general updates from the Service Galaxy. These may include event updates, surveys, and other relevant information",
      // image: 'assets/images/close-circle-fill.png',
      button: "Unsubscribe",
      link: "",
    },
    session: {
      heading: "Unsubscribe from Service Galaxy Emails",
      subHeading:
        "You are opting out of receiving general updates from the Service Galaxy. These may include event updates, surveys, and other relevant information",
      // image: 'assets/images/close-circle-fill.png',
      button: "Unsubscribe",
      link: "",
    },
    success: {
      heading: "Unsubscribe from Service Galaxy Emails",
      subHeading:
        "You are opting out of receiving general updates from the Service Galaxy. These may include event updates, surveys, and other relevant information",
      // image: 'assets/images/close-circle-fill.png',
      button: "Successfully Unsubscribed",
      link: "",
    },
    failed: {
      heading: "Unsubscribe from Service Galaxy Emails",
      subHeading:
        "You are opting out of receiving general updates from the Service Galaxy. These may include event updates, surveys, and other relevant information",
      // image: 'assets/images/close-circle-fill.png',
      button: "Successfully Unsubscribed",
      linn: "",
    },
  },
  OPPORTUNITY: {
    DELETE: {
      sayYes: "Delete",
      title: "Are you sure you want to delete this opportunity?",
      img: "assets/images/delete.png",
      sayNo: "Cancel",
    },
    CREATED: {
      sayYes: "Create Customer",
      title: "Opportunity Created",
      subTitle: "Please add a customer to the oppId",
      img: "assets/images/add_create_new_plus_icon.svg",
      sayNo: "Cancel",
    },
    INVOICE: {
      sayYes: "Download",
      subTitle: "Are you sure you want to download the invoice?",
      title: "Download Invoice",
      sayNo: "Cancel",
    },
  },
  SCHEDULE: {
    DELETE: {
      sayYes: "Ok",
      title: "Are you sure you want to remove this event from the calendar?",
      img: "",
      sayNo: "Cancel",
    },
    UNSCHEDULE: {
      sayYes: "Unschedule",
      title: "Are you sure you want to remove this event from the calendar?",
      img: "",
      sayNo: "Cancel",
    },
  },
  JOB: {
    DELETE: {
      sayYes: "Delete",
      title: "Are you sure you want to delete this job?",
      img: "assets/images/delete.png",
      sayNo: "Cancel",
    },
  },
  NOTES: {
    DELETE: {
      sayYes: "Delete",
      title: "Are you sure you want to delete this Note?",
      img: "assets/images/delete.png",
      sayNo: "Cancel",
    },
  },
  QCNOTES: {
    DELETE: {
      sayYes: "Delete",
      title: "Are you sure you want to delete this Qc Note?",
      img: "assets/images/delete.png",
      sayNo: "Cancel",
    },
  },
  REMINDER: {
    DELETE: {
      sayYes: "Delete",
      title: "Are you sure you want to delete this Reminder?",
      img: "assets/images/delete.png",
      sayNo: "Cancel",
    },
  },
  EXPENSE: {
    DELETE: {
      sayYes: "Delete",
      title: "Are you sure you want to delete this Expense?",
      img: "assets/images/delete.png",
      sayNo: "Cancel",
    },
  },
  CUSTOMER: {
    DELETE: {
      sayYes: "Archive",
      title: "Are you sure you want to archive this customer?",
      img: "assets/images/delete.png",
      sayNo: "Cancel",
    },
    RESTORE: {
      sayYes: "Yes",
      title: "Are you sure you want to restore this customer?",
      img: "assets/images/restore_icon.svg",
      sayNo: "No",
    },
    ROUTE: {
      sayYes: "AIMR",
      sayNo: "Stay in Opportunity",
    },
  },
  AIMR: {
    includeText:
      "This toggle allows you to Include the drive to and drive from times when looking for available time slots, or exclude the drive to and drive from time when looking for available time slots.",
    hideText:
      "This toggle allows you to show or hide time slots that are shorter than the service duration.",
  },
  SCRIPTING: {
    DELETE: {
      sayYes: "Delete",
      title: "Are you sure you want to delete this page?",
      img: "assets/images/delete.png",
      sayNo: "Cancel",
    },
  },
  FOLDER: {
    DELETE: {
      sayYes: "Delete",
      title: "Are you sure you want to delete the folder",
      img: "assets/images/delete.png",
      sayNo: "Cancel",
    },
    PUBLISH: {
      sayYes: "Publish",
      title: "Are you sure you want to publish the folder",
      img: "assets/images/delete.png",
      sayNo: "Cancel",
    },
    UNPUBLISH: {
      sayYes: "Un-publish",
      title: "Are you sure you want to unpublish the folder",
      img: "assets/images/delete.png",
      sayNo: "Cancel",
    },
    DEFAULT: {
      sayYes: "Assign For AIMR",
      title: "Are you sure you want to assign the script for AIMR",
      img: "assets/images/delete.png",
      sayNo: "Cancel",
    },
    DEFAULT_REMOVE: {
      sayYes: "Remove From AIMR",
      title: "Are you sure you want to remove the script from AIMR",
      img: "assets/images/delete.png",
      sayNo: "Cancel",
    },
  },
  CLIENT: {
    BLOCK: {
      sayYes: "Block",
      title: "Are you sure you want to block this client with name ",
      img: "../../../../assets/images/block.png",
      sayNo: "Cancel",
    },
    UNBLOCK: {
      sayYes: "Unblock",
      title: "Are you sure you want to unblock this client with name ",
      img: "../../../../assets/images/block.png",
      sayNo: "Cancel",
    },
    DELETE: {
      sayYes: "Delete",
      title: "This client will be removed permanently & cannot be recovered!!",
      img: "../../../../assets/images/delete.png",
      sayNo: "Cancel",
    },
  },
  EMPLOYEE: {
    BLOCK: {
      sayYes: "Block",
      title: "Are you sure you want to block this employee with name",
      img: "assets/images/indeterminate-circle-fill.svg",
      sayNo: "Cancel",
    },
    DELETE: {
      sayYes: "Archive",
      title: "Are you sure you want to archive this employee with name ",
      img: "../../../../assets/images/delete.png",
      sayNo: "Cancel",
    },
    RESTORE: {
      sayYes: "Yes",
      title: "Are you sure you want to restore this employee?",
      img: "assets/images/restore_icon.svg",
      sayNo: "No",
    },
  },
  LOGOUT: {
    logout: "Logout",
    title: "Are you sure you want to logout?",
    cancel: "Cancel",
  },
  FORGOTPASSWORD: {
    successMsg:
      "Reset password link has been sent successfully to your email id!!",
    errorMsg: "Email not found.Please check your email!!",
  },
  RESENDLINK: {
    successMsg:
      "Verification link has been sent successfully to your email id!!",
  },
  VERIFYEMAIL: {
    success: {
      heading: "Verification Successful",
      subHeading: "Click on reset button to reset your password.",
      image: "assets/images/check.svg",
      button: "Set Password",
    },
    failed: {
      heading: "Verification Failed",
      subHeading: "Please check you email address.",
      image: "assets/images/close-circle-fill.png",
      button: "Back to Login",
    },
    session: {
      heading: "Session Expired",
      subHeading: "",
      image: "assets/images/close-circle-fill.png",
      button: "Back to Login",
    },
  },
  PASSWORDUPDATE: {
    heading: "Password Updated",
    subHeading: "Your Password has been successfully Changed.",
    image: "assets/images/check.svg",
    button: "Back to Login",
  },
  ROLEBLOCK: {
    title:
      "Are you sure you want to block this Role & all associated employees?",
    image: "assets/images/indeterminate-circle-fill.svg",
  },
  ROLEDELETE: {
    title: "Are you sure you want to delete?",
    image: "assets/images/delete.png",
  },

  VALIDATION: {
    email: "Email id is required.",
    validEmail: "Please enter a valid email address",
    password: "Please type your password",
    services: "Services is required.",
    companyName: "Company Name is required.",
    userName: "Username is required.",
    clientName: "Client Name is required.",
    phoneNumber: "Phone Number is required.",
    contact: "Contact is required.",
    address: "Address is required.",
    city: "City is required.",
    state: "State is required.",
    country: "Country is required.",
    zipCode: "Zipcode is required.",
    profile: "Profile image is required.",
    newPassword: "New Password is required.",
    taskName: "Task name is required.",
    startDate: "Start date is required.",
    endDate: "End date is required.",
    oldPassword: "Current Password is required.",
    confirmPassword: "Confirm Password is required.",
    validNewPassword: "Enter a valid password",
    validZipCode: "Enter a valid Zipcode",
    firstName: "First name is required.",
    lastName: "Last name is required.",
    roleName: "Role name is required.",
    startTime: "Start Time is required.",
    endTime: "End Time is required.",
    shortDistance: "Short Distance is required.",
    mediumDistance: "Medium  Distance is required.",
    longDistance: "Long  Distance is required.",
    opportunity: "Opportunity is required.",
    description: "Description is required.",
    hireDate: "Hire Date is required.",
    notes: "Notes are required.",
    date: "Date is required.",
    time: "Time is required.",
    category: "Phone Category is required.",
    skills: "Skills are required.",
    assignedTo: "Assigned To is required.",
    customer: "Customer is required.",
    folderName: "Folder Name is required.",
    folderLength: "Folder Name length should be between 5 and 25",
    folderDesc: "Description length should be between 5 and 250",
    serviceName: "Service Name is required.",
    descriptionTitle: "Description title is required.",
    jobId: "Please select a job",
    employeeReminder: "Select atleast 1 employee",
    vendor: "Vendor is required",
    employee: "Please select an employee",
    paymentType: "Payment Type is required",
    amount: "Amount is required",
    amountPattern: "Enter a valid amount",
    po: "PO is required",
    receipt: "Receipt is required",
    image: "Image is required",
    timeRange: "Please select time range between 9:00 AM to 18:00 PM",
    weeks: "Weeks are required",
    invalidWeek: "Week must be between 1 and 9",
    quantity: "Item quantity is required",
    maxDistance: "Max Distance is required",
    reasonJob: "Reason is required",
    reasonOpp: "Reason is required",
    workingAddress: "Work Address is required",
  },
  mapStyle: [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#ebe3cd",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#523735",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#f5f1e6",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#c9b2a6",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#dcd2be",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#ae9e90",
        },
      ],
    },
    {
      featureType: "administrative.neighborhood",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "landscape.natural",
      elementType: "geometry",
      stylers: [
        {
          color: "#dfd2ae",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#dfd2ae",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#93817c",
        },
      ],
    },
    {
      featureType: "poi.business",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#a5b076",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#447530",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f1e6",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [
        {
          color: "#fdfcf8",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#f8c967",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#e9bc62",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.highway.controlled_access",
      elementType: "geometry",
      stylers: [
        {
          color: "#e98d58",
        },
      ],
    },
    {
      featureType: "road.highway.controlled_access",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#db8555",
        },
      ],
    },
    {
      featureType: "road.local",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#806b63",
        },
      ],
    },
    {
      featureType: "transit",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#dfd2ae",
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#8f7d77",
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#ebe3cd",
        },
      ],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#dfd2ae",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#b9d3c2",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#92998d",
        },
      ],
    },
  ],
  momentRange: {
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "Last 1 Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
    "Last 3 Months": [
      moment().subtract(3, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
    "Last 12 Months": [
      moment().subtract(12, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  },
  comingMomentRange: {
    Today: [moment().subtract(0, "days"), moment().subtract(0, "days")],
    Tommorow: [moment().add(1, "days"), moment().add(1, "days")],
    "Upcoming 7 Days": [moment().add(6, "days"), moment()],
    "Upcoming 30 Days": [moment().add(29, "days"), moment()],
    "Upcoming 1 Month": [
      moment().add(1, "month").startOf("month"),
      moment().add(1, "month").endOf("month"),
    ],
    "Upcomng 3 Months": [
      moment().add(3, "month").startOf("month"),
      moment().add(1, "month").endOf("month"),
    ],
    "Upcoming 12 Months": [
      moment().add(12, "month").startOf("month"),
      moment().add(1, "month").endOf("month"),
    ],
  },
  reportRange: {
    Today: [moment(), moment()],
    "Last Week": [
      moment().subtract(1, "week").startOf("week"),
      moment().subtract(1, "week").endOf("week"),
    ],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
    "Last Year": [
      moment().subtract(1, "year").startOf("year"),
      moment().subtract(1, "year").endOf("year"),
    ],
  },
  alwaysShowCalendars: false,
  showRangeLabelOnInput: true,
  keepCalendarOpeningWithRange: true,
  monthArray: [
    "",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
};
