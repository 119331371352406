import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSignaturePadComponent } from '@mr-samani/ngx-signature-pad';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppConst, APP_CONSTANT } from 'src/app/core/_constants/app.constant';
import { UploadService } from 'src/app/core/_services/upload.service';

@Component({
  selector: 'app-collect-signature',
  templateUrl: './collect-signature.component.html',
  styleUrls: ['./collect-signature.component.scss']
})
export class CollectSignatureComponent implements OnInit {
  @ViewChild('signaturePad') signaturePad: any;

  open: any;
  uploadedFiles: any;
  imageUrl: any;
  // imageInvalidMsg: any;
  // imageInvalid: boolean = false;
  signature: string = '';
  isSignatureNotAdded: boolean = true;

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setCanvasSize();
    }, 500)
  }


  constructor(public dialogRef: MatDialogRef<CollectSignatureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private loader: NgxUiLoaderService,
    private toastr: ToastrService,
    private upload: UploadService
  ) { }

  ngOnInit(): void {
  }

  setCanvasSize() {
    let widthDiv = document.querySelector('.signPad')
    const canvas = this.signaturePad.canvas;
    canvas.nativeElement.width = widthDiv.clientWidth;
  }

  reset() {
    this.imageUrl = ''
  }
  approveSignature() {
    if (this.imageUrl != '' && this.imageUrl != null && this.imageUrl != undefined) {
      this.dialogRef.close(this.imageUrl);
    }
    else {
      // this.imageInvalidMsg =  APP_CONSTANT.VALIDATION.signature;
    }
  }
  async fileupload(e: any) {
    const selectedFile = e;
    if (e) {
      this.loader.start();

      if ((selectedFile.type.match(/png/) == null)) {
        // this.imageInvalidMsg = APP_CONSTANT.VALIDATION.imageExt;
        // this.imageInvalid = true;
        this.loader.stop();
        return false;
      }
      const randomValue = Math.random().toString(36).substring(2, 15); // Generate a random string
      const newFileName = this.addRandomValueToFileName(selectedFile.name, randomValue);
      const newFile = new File([selectedFile], newFileName, { type: selectedFile.type });

      let uploadedImage: any = await this.upload.uploadFile(newFile);
      if (uploadedImage) {
        this.imageUrl = uploadedImage;
        // this.imageInvalidMsg = "";
        // this.imageInvalid = false;
        this.loader.stop();
        this.approveSignature();
        return true;
      } else {
        this.loader.stop();
        this.toastr.error(`${AppConst.ERROR_MESSAGES.SERVER_ERROR}`)
        return false;
      }
    }
    this.loader.stop()
    return false;

  }
  addRandomValueToFileName(fileName: string, randomValue: string): string {
    const dotIndex = fileName.lastIndexOf('.');
    if (dotIndex === -1) {
      // No extension found, just append the random value
      return `${fileName}_${randomValue}`;
    } else {
      const name = fileName.substring(0, dotIndex);
      const extension = fileName.substring(dotIndex);
      return `${name}_${randomValue}${extension}`;
    }
  }

  getSignature(ev: string) {
    this.isSignatureNotAdded = this.isCanvasBlank(this.signaturePad.ctx)
  }

  clear(pad: NgxSignaturePadComponent) {
    pad.clearSignaturePad();
    this.isSignatureNotAdded = this.isCanvasBlank(this.signaturePad.ctx)
    this.signature = '';
    this.imageUrl = '';
  }

  isCanvasBlank(canvas: any) {
    const context = canvas;
    const pixelBuffer = new Uint32Array(
      context.getImageData(0, 0, 550, 240).data.buffer
    );

    return (!pixelBuffer.some(color => color !== 0));
  }

  getFile(pad: NgxSignaturePadComponent) {
    this.fileupload(pad.getSignatureFile());
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setCanvasSize()
  }
}
