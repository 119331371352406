import { environment } from "src/environments/environment";

export const API_ROUTES = {
    ACCOUNT: {
        login: `${environment.apiUrl}v1/client/login`,
        forgetPassword: `${environment.apiUrl}v1/client/forgetPassword`,
        resetPassword: `${environment.apiUrl}v1/client/resetPassword`,
        verifyEmail: `${environment.apiUrl}v1/client/emailVerification`,
        viewProfile: `${environment.apiUrl}v1/client/viewProfile`,
        changePassword:`${environment.apiUrl}v1/client/changePassword`,
        editProfile: `${environment.apiUrl}v1/client/update`,
        loginAsSuperAdmin : `${environment.apiUrl}v1/client/loginAsSuperAdmin`,
        resendVerificationLink : `${environment.apiUrl}v1/client/resendVerificationLink`,
        unsubscribe:`${environment.apiUrl}v1/customers/unsubscribeEmail`,
        pushNotification:`${environment.apiUrl}v1/client/pushNotification`
    },
    TASK: {
        getTask: `${environment.apiUrl}v1/tasks/getTasks`,
        createTask: `${environment.apiUrl}v1/tasks/createTask`,
        editTask: `${environment.apiUrl}v1/tasks/updateTask`,
    },
    EMPLOYEE: {
        getEmployee: `${environment.apiUrl}v1/employees/employees`,
        availableTechnician: `${environment.apiUrl}v1/employees/availableTechnician`,
        getEmployeeBySkill: `${environment.apiUrl}v1/employees/empListBySkill`,
        getEmployeeById: `${environment.apiUrl}v1/employees/employeeDetails`,
        deleteEmployee: `${environment.apiUrl}v1/employees/delete`,
        blockEmployee: `${environment.apiUrl}v1/employees/block`,
        addNote: `${environment.apiUrl}v1/employees/addNotes`,
        addFiles: `${environment.apiUrl}v1/employees/addFiles`,
        deleteFile: `${environment.apiUrl}v1/employees/deleteFiles`,
        editEmployee: `${environment.apiUrl}v1/employees/editEmployee`,
        addEmployee: `${environment.apiUrl}v1/employees/invite`,
        empService: `${environment.apiUrl}v1/employees/empService`,
        empArchive: `${environment.apiUrl}v1/employees/getArchiveList`,
        empRestore: `${environment.apiUrl}v1/employees/restore`,
        upload: `${environment.apiUrl}v1/employees/bulkUpload`,
        getTimeSlotForEmployee: `${environment.apiUrl}v1/employees/timeSlotForEmployee`,
        empListForReassignEvents: `${environment.apiUrl}v1/employees/empListForReassignEvents`,
        employeeChatList: `${environment.apiUrl}v1/client/teamChatList`,
        createChatGroup: `${environment.apiUrl}v1/client/createTeamChatGroup `
    },
    OPPORTUNITY: {
        getOppCount: `${environment.apiUrl}v1/dashboard/opportunityCount`,
        getOppList: `${environment.apiUrl}v1/opportunity/oppList`,
        addOpp: `${environment.apiUrl}v1/opportunity/createOpportunity`,
        editOpp: `${environment.apiUrl}v1/opportunity/editOpp`,
        deleteOpp: `${environment.apiUrl}v1/opportunity/deleteOpportunity`,
        deleteNote: `${environment.apiUrl}v1/opportunity/deleteNotes`,
        addNote: `${environment.apiUrl}v1/opportunity/addNotes`,
        editNote: `${environment.apiUrl}v1/opportunity/updateNote`,
        addFiles: `${environment.apiUrl}v1/opportunity/addFiles`,
        addReminder: `${environment.apiUrl}v1/opportunity/addReminder`,
        getDetail: `${environment.apiUrl}v1/opportunity/oppDetails`,
        updateOpp: `${environment.apiUrl}v1/opportunity/updateOpportunityAndEvent`,
        addEstimate: `${environment.apiUrl}v1/opportunity/addEstimate`,
        updateEstimate: `${environment.apiUrl}v1/opportunity/updateEstimate`,
        deleteEstimate: `${environment.apiUrl}v1/opportunity/deleteEstimate`,
        approveEstimates: `${environment.apiUrl}v1/opportunity/approveEstimates`,
        inRoute: `${environment.apiUrl}v1/opportunity/inRoute`,
        addPayment: `${environment.apiUrl}v1/opportunity/addPayments`,
        getJobDetail: `${environment.apiUrl}v1/opportunity/jobDetails`,
        editJob: `${environment.apiUrl}v1/opportunity/editJob`,
        deleteJob: `${environment.apiUrl}v1/opportunity/deleteJob`,
        deleteFile: `${environment.apiUrl}v1/opportunity/deleteFiles`,
        deleteReminder: `${environment.apiUrl}v1/opportunity/deleteReminder`,
        addExpense:  `${environment.apiUrl}v1/opportunity/addExpense`,
        updateExpense:  `${environment.apiUrl}v1/opportunity/editExpense`,
        deleteExpense: `${environment.apiUrl}v1/opportunity/deleteExpense`,
        addQc: `${environment.apiUrl}v1/opportunity/addQcNotes`,
        deleteQc: `${environment.apiUrl}v1/opportunity/deleteQCNotes`,
        getHistory: `${environment.apiUrl}v1/opportunity/activityLogs`,
        updateWorkOrder: `${environment.apiUrl}v1/opportunity/updateWorkOrder`,
        previewWorkOrder : `${environment.apiUrl}v1/opportunity/previewWorkOrder`,
        reopen: `${environment.apiUrl}v1/opportunity/reopen`,
        appendForm: `${environment.apiUrl}v1/opportunity/appendForm`,
        detachForm:`${environment.apiUrl}v1/opportunity/deattachForm`,
        populateForm: `${environment.apiUrl}v1/dynamicForms/populateForm`,
        downloadForm: `${environment.apiUrl}v1/dynamicForms/previewFormPdf`
    },
    PAYMENT: {
        checkout: `${environment.apiUrl}v1/opportunity/checkout`
    },
    SCRIPTING:{
        createFolder:  `${environment.apiUrl}v1/scripts/createFolder`,
        getScripts:  `${environment.apiUrl}v1/scripts/getScripts`,
        editScript:  `${environment.apiUrl}v1/scripts/editScript`,
        getScriptList:  `${environment.apiUrl}v1/scripts/getScriptList`,
        updateFolder:  `${environment.apiUrl}v1/scripts/updateFolder`,
        deleteFolder:  `${environment.apiUrl}v1/scripts/deleteFolder`,
        createScript:  `${environment.apiUrl}v1/scripts/createScript`,
        deleteScript:  `${environment.apiUrl}v1/scripts/deleteScript`,
        linkScript:  `${environment.apiUrl}v1/scripts/linkScript`,
        scriptListForLink:  `${environment.apiUrl}v1/scripts/scriptListForLink`,
        publishedScript: `${environment.apiUrl}v1/scripts/publishedScript`,
    },
    JOB: {
        getWorkorder: `${environment.apiUrl}v1/opportunity/getWorkOrders`,
    },
    CUSTOMER: {
        addCustomer:`${environment.apiUrl}v1/customers/inviteCustomer`,
        customerList:`${environment.apiUrl}v1/customers/customerList`,
        customerListBySearch: `${environment.apiUrl}v1/customers/customerBySearch`,
        editCustomer:`${environment.apiUrl}v1/customers/editCustomer`,
        deleteCustomer: `${environment.apiUrl}v1/customers/deleteCustomer`,
        getCustomerDetails: `${environment.apiUrl}v1/customers/customerDetail`,
        setHideDetailsOfCustomer: `${environment.apiUrl}v1/customers/setHideDetailsOfCustomer`,
        getHideDetailsOfCustomer: `${environment.apiUrl}v1/customers/getHideDetailsOfCustomer`,
        addNote: `${environment.apiUrl}v1/customers/addNotes`,
        editNote: `${environment.apiUrl}v1/customers/editNotes`,
        deleteNote: `${environment.apiUrl}v1/customers/deleteNotes`,
        addFiles: `${environment.apiUrl}v1/customers/addFiles`,
        deleteFile: `${environment.apiUrl}v1/customers/deleteFiles`,
        customerArchive: `${environment.apiUrl}v1/customers/CustomerListForArchive`,
        restoreCustomer: `${environment.apiUrl}v1/customers/restoreCustomer`,
        upload: `${environment.apiUrl}v1/customers/bulkUpload`,
        customerListForChat: `${environment.apiUrl}v1/customers/customerListForChat`,
        getHistory: `${environment.apiUrl}v1/customers/activityLogs`,
        mergeCustomer: `${environment.apiUrl}v1/customers/mergeCustomer`,
        customerAddress: `${environment.apiUrl}v1/customers/nearestCompany`,
    },
    REPORT: {
        oppCount: `${environment.apiUrl}v1/dashboard/oppCount`,
        appointmentCount: `${environment.apiUrl}v1/dashboard/appointmentCount`,
        services: `${environment.apiUrl}v1/dashboard/serviceWithRevenue`,
        itemWithRevenue: `${environment.apiUrl}v1/dashboard/itemWithRevenue`,
        salesPerPerson: `${environment.apiUrl}v1/reporting/salesPerPerson`,
        totalSales: `${environment.apiUrl}v1/dashboard/totalSales`,
        amountApproved: `${environment.apiUrl}v1/dashboard/amountApproved`,
        actualRevenue: `${environment.apiUrl}v1/dashboard/actualRevenue`,
        estimateCount: `${environment.apiUrl}v1/dashboard/estimateCount`
    },
    EVENTS: {
        myEvents: `${environment.apiUrl}v1/dashboard/myEvents`,
        createEvent: `${environment.apiUrl}v1/events/createEvent`,
        updateEvent: `${environment.apiUrl}v1/events/updateEvent`,
        deleteEvent: `${environment.apiUrl}v1/events/deleteEvent`,
        getEventsForCalendar: `${environment.apiUrl}v1/events/getEventsForCalendar`,
        getEventsForDispatch : `${environment.apiUrl}v1/events/getEmployeeEvent`,
        updateEventDescription:`${environment.apiUrl}v1/events/updateEventDescription`,
        totalEventsWithCount: `${environment.apiUrl}v1/dashboard/totalEventsWithCount`,
        updateEventDuration: `${environment.apiUrl}v1/events/updateEventDuration`,
        createGroupsForDispatch : `${environment.apiUrl}v1/employees/createDispatchGroup`,
        getAllGroup: `${environment.apiUrl}v1/employees/getAllGroups`
    },
    DASHBOARD:{
        customerCount: `${environment.apiUrl}v1/dashboard/customerCount`,
        jobCount: `${environment.apiUrl}v1/dashboard/jobCount`,
        employeeCount: `${environment.apiUrl}v1/dashboard/employeeCount`,
        totalEvents: `${environment.apiUrl}v1/dashboard/totalEvents`,
        routing: `${environment.apiUrl}v1/dashboard/routing`,
    },
    SERVICE: {
        getServiceList: `${environment.apiUrl}v1/items/getItems?chargeType=Service`,
    },
    DESCRIPTION: {
        getDescriptionList: `${environment.apiUrl}v1/description/getDescription`,
        deleteDescription: `${environment.apiUrl}v1/description/deleteDescription`,
        createDescription: `${environment.apiUrl}v1/description/addDescription`,
    },
    SKILL: {
        getSkillsForService: `${environment.apiUrl}v1/skills/skillsForService`,
        getSkills: `${environment.apiUrl}v1/skills/skills`,
        deleteSkill: `${environment.apiUrl}v1/skills/delete`,
        addSkill: `${environment.apiUrl}v1/skills/create`,
    },
    SETTING: {
        getTimeSlots:  `${environment.apiUrl}v1/setting/timeSlotSize`,
        timeSlotSize:  `${environment.apiUrl}v1/setting/timeSlotSize`,
        settimeSlotSize:  `${environment.apiUrl}v1/setting/settimeSlotSize`,
        setDistanceFormat:  `${environment.apiUrl}v1/setting/setDistanceFormat`,
        getDistanceFormat:  `${environment.apiUrl}v1/setting/getDistanceFormat`,
        getWeeksForTimeSlot:  `${environment.apiUrl}v1/setting/getWeeksForTimeSlot`,
        setWeeksForTimeSlot:  `${environment.apiUrl}v1/setting/setWeeksForTimeSlot`,
        setDailyGoal:  `${environment.apiUrl}v1/setting/setDailyGoal`,
        getDailyGoal:  `${environment.apiUrl}v1/setting/getDailyGoal`,
        getInspectionBtnStatus:`${environment.apiUrl}v1/inspection/inspections`,
        updateInspectionBtnStaus:`${environment.apiUrl}v1/inspection/updateInspections`,
        syncTimeAndFrequency: `${environment.apiUrl}v1/quickBook/qbSyncSetting`,
        getValorCreds: `${environment.apiUrl}v1/setting/CompanyValorAccount`,
        updateValorCreds: `${environment.apiUrl}v1/setting/valorAccount`
    },
    SCHEDULE: {
        scheduleEvent: `${environment.apiUrl}v1/opportunity/scheduleEvent`
    },
    ROLES: {
        getRoles: `${environment.apiUrl}v1/roles/roles`,
        createRole: `${environment.apiUrl}v1/roles/create`,
        deleteRole: `${environment.apiUrl}v1/roles/delete`,
        workingAddres:`${environment.apiUrl}v1/client/workingAddress`,
    },
    COACHING: {
        getFolders: `${environment.apiUrl}v1/videos/getFolders`,
        getVideos: `${environment.apiUrl}v1/videos/getVideos`,
        getVideoDetail: `${environment.apiUrl}v1/videos/getVideoById`,
        getFeeds: `${environment.apiUrl}v1/feeds/getFeedList`,
        addComment: `${environment.apiUrl}v1/commentLikes/addComment`,
        addLikes: `${environment.apiUrl}v1/commentLikes/addLikes`,
        unLike: `${environment.apiUrl}v1/commentLikes/unLikeData`,
        addViews: `${environment.apiUrl}v1/commentLikes/addViews`,
        saveVideo: `${environment.apiUrl}v1/videos/savedVideos`,
        getSavedVideos: `${environment.apiUrl}v1/videos/getSavedVideos`,
        getFeedDetail: `${environment.apiUrl}v1/feeds/getFeedById`,
        createFeedGroup: `${environment.apiUrl}v1/videos/createFolder`,
        getGroupList: `${environment.apiUrl}v1/videos/getFolders`,
        deleteGroup: `${environment.apiUrl}v1/videos/deleteFolder`,
        createFeed: `${environment.apiUrl}v1/feeds/createFeed`,
        deleteFeed:`${environment.apiUrl}v1/feeds/deleteFeed`,
        getFeedCount:`${environment.apiUrl}v1/notifications/updateNotificationSeen`
    },
    ITEMS: {
        addItem: `${environment.apiUrl}v1/items/createItem`,
        getItems: `${environment.apiUrl}v1/items/getItems`,
        deleteItem: `${environment.apiUrl}v1/items/deleteItem`,
        createBundle: `${environment.apiUrl}v1/itemBundle/createBundle`,
        getBundles: `${environment.apiUrl}v1/itemBundle/getBundles`,
        deleteBundle: `${environment.apiUrl}v1/itemBundle/deleteBundle`,
        getItemsForBundle: `${environment.apiUrl}v1/items/getItemsForBundle`,
        updateBundleItems: `${environment.apiUrl}v1/itemBundle/updateBundleItem`,
        syncQuickbook: `${environment.apiUrl}v1/quickBook/connect`,
        syncQuickbookItems: `${environment.apiUrl}v1/quickBook/syncItems`,
        callback: `${environment.apiUrl}v1/quickBook/callback`,
        bulkImport: `${environment.apiUrl}v1/items/bulkImport`,
        syncQuickbookInovice: `${environment.apiUrl}v1/quickBook/syncInvoices`,
        


    },
    SOURCE: {
        addSource: `${environment.apiUrl}v1/source/addSource`,
        getSource: `${environment.apiUrl}v1/source/getSourceList`,
        deleteSource: `${environment.apiUrl}v1/source/deleteSource`,
    },
    TAX: {
        addTax: `${environment.apiUrl}v1/tax/addTax`,
        getTax: `${environment.apiUrl}v1/tax/getTaxList`,
        deleteTax: `${environment.apiUrl}v1/tax/deleteTax`,
    },
    MEETING: {
        getMeetings: `${environment.apiUrl}v1/meetings/meetingList`,
        getSignature: `${environment.apiUrl}v1/meetings/verifySign`,
        updateStatus: `${environment.apiUrl}v1/meetings/updateMeetingStatus`
    },
    NOTIFICATION: {
        getCoachingNotifications: `${environment.apiUrl}v1/notifications/notifications`,
        clearAllNotifications: `${environment.apiUrl}v1/notifications/clearAllNotifications`,
        updateNotificationSeen: `${environment.apiUrl}v1/notifications/updateNotificationSeen`
    },
    LOGOUT: `${environment.apiUrl}v1/client/logout`,
    CLIENT:{
        createSubscription: `${environment.apiUrl}v1/subscriptions/createSubscription`,
        publicCreateSubscription: `${environment.apiUrl}v1/subscriptions/publicCreateSubscription`,
        subscriptionList: `${environment.apiUrl}v1/subscriptions/getSubscription`
    },
    SETTING_NOTIFICATION:{
        getNotification: `${environment.apiUrl}v1/settingNotifications/notifications`,
        create: `${environment.apiUrl}v1/settingNotifications/create`
    },
    REPORT_MODULE:{
        companySalesData: `${environment.apiUrl}v1/reporting/companySaleData`,
        jobRevenue: `${environment.apiUrl}v1/reporting/jobRevenue`,
        salesPerPerson: `${environment.apiUrl}v1/reporting/salesPerPerson`,
        salesProduction: `${environment.apiUrl}v1/reporting/salesProduction`,
        revenue: `${environment.apiUrl}v1/reporting/revenue`,
    },
    EMAIL:{
        getEMail:`${environment.apiUrl}v1/setting/getMasterEmail`,
        patchEMail:`${environment.apiUrl}v1/setting/updateMasterEmail`,

    },
    FORMS:{
        addForm:`${environment.apiUrl}v1/dynamicForms/addForm`,
        editForm:`${environment.apiUrl}v1/dynamicForms/edit`,
        formList:`${environment.apiUrl}v1/dynamicForms/formList`,
        deleteFrom:`${environment.apiUrl}v1/dynamicForms/deleteForm`,
        getFormById:`${environment.apiUrl}v1/dynamicForms/getFormById`
    },
    Reminder:{
        editReminder:`${environment.apiUrl}v1/events/updateReminder `,
    },
    Campaign:{
        campaignList:`${environment.apiUrl}v1/campaign/campaignListing`,
        getList:`${environment.apiUrl}v1/campaign/populateCampaignFilter`,
        customerList:`${environment.apiUrl}v1/campaign/customerList`,
        createCampaign:`${environment.apiUrl}v1/campaign/createCampaign`,
        campaginDetail:`${environment.apiUrl}v1/campaign/campaignDetail`,
        campaginDelete:`${environment.apiUrl}v1/campaign/deleteCampaign`,
        testEmail:`${environment.apiUrl}v1/campaign/testMail`,
        importCSV:`${environment.apiUrl}v1/campaign/importCsv`




    },
    MESSAGE: {
        deleteGroup: `${environment.apiUrl}v1/client/removeGroup `
    },
    INVOICE: {
        getListing: `${environment.apiUrl}v1/client/invoiceList`
    },
    EMAILEDITOR: {
        addTemplate: `${environment.apiUrl}v1/template/addTemplate`,
        getTemplate: `${environment.apiUrl}v1/template/templateListing`,
        deleteTemplate:`${environment.apiUrl}v1/template/deleteTemplate`,
        getTemplateDetail:`${environment.apiUrl}v1/template/templateDetail`,


    },
    QUICKBOOK:{
        quickBooklist:`${environment.apiUrl}v1/quickbook/qbLogs`,
        quickBookDeactivate:`${environment.apiUrl}v1/quickbook/disconnectQbAccount`,
        quickBookReactivate:`${environment.apiUrl}v1/quickbook/reconnectQbAccount`,


    }
    
}