import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { map, catchError, timeout } from 'rxjs/operators';
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { LocalStorageProvider } from '../_services/storage.service';
import { throwError, Observable } from 'rxjs';
import { MainService } from '../_services/main.service';
import { API_ROUTES } from "../_constants/api-route.constant";
import { RouteConstant } from "../_constants/route.constant";
import { Router } from "@angular/router";


@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {

    constructor(
        private storageService: LocalStorageProvider,
        private service: MainService,
        private toastr: ToastrService,
        private loader: NgxUiLoaderService,
        private router: Router
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        /*Fetching the token from the localStorage and adding in Authorisation Headers */
        if (!window.navigator.onLine) {
            // if there is no internet, throw a HttpErrorResponse error
            // since an error is thrown, the function will terminate here
            // return new HttpErrorResponse({ error: 'Internet is required.' });
            this.toastr.error('You have No internet Connection.')
            return throwError(() => {return new HttpErrorResponse({ error: 'Internet is required.' })})
        }else {
        let apiToken = this.storageService.getItem('token');

        // this.loader.start();
        if (!request.headers.has('Content-Type') && request.url !== `${API_ROUTES.EMPLOYEE.upload}` && request.url !== `${API_ROUTES.CUSTOMER.upload}`  && request.url !== `${API_ROUTES.ITEMS.bulkImport}` &&  request.url !== `${API_ROUTES.Campaign.importCSV}`) {
            request =  request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }
        if (!request.headers.has('Accept')) {
            request = request.clone({ headers: request.headers.set('Accept', '*/*') });
        }
        if (!request.headers.has('Access-Control-Allow-Origin')){
            request = request.clone({ headers: request.headers.set('Access-Control-Allow-Origin', '*') });
        } 
        if(!request.headers.has('timezone')){
          request= request.clone({headers: request.headers.set('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone)})
        }
        if (apiToken) {
            request = request.clone({ headers: request.headers.set('Authorization',`Bearer ${apiToken}`) });
        }  
        // console.log('request--->>>', request);
        return next.handle(request).pipe(
            timeout(25000),
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log('response--->>>', event);
                }
                // this.loader.stop();
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.message == "Timeout has occurred") {
                    this.toastr.error(error.message);
                    this.service.clearData();

                }else {
                    if(error.status == 412){
                        this.toastr.error('Role updated!!');
                        // this.service.logout();
                        this.refreshProfilePermissions()
                    }
                    if (error.status == 401 || error.status == 0) {
                        this.toastr.error(error?.statusText,"Error");
                        this.service.clearData();
                    }
                    if(error.status == 403){
                        // this.toastr.error(error?.error?.msg,"Error");  
                        this.toastr.error('Subscription suspended, you are being logged out',"Error");
                        this.service.clearData();
                        
                    }
                }
                return throwError(() => {return error;});
            })
        )
    }
}


refreshProfilePermissions(){
  this.loader.start();
  this.service.get(`${API_ROUTES.ACCOUNT.viewProfile}`,{}).pipe().subscribe((response: any) => {
    if(response.status){
      const adminProfile = response.data[0];
      this.storageService.setItem('CurrentUser',adminProfile);
      this.service.updateUser(response.data[0]);
      if(adminProfile?.roleId){
        const roleData = adminProfile && adminProfile?.role_data ? adminProfile?.role_data[0]: {};
        if((roleData?.dashboardCustomerView || roleData?.dashboardEmployeeView || roleData?.dashboardEventView || roleData?.dashboardJobView || roleData?.dashboardOpportunityView || roleData?.dashboardReportingView || roleData?.dashboardTaskView || roleData?.dashboardMyEventView || roleData?.dashboardNotificationView)){
          this.router.navigate([`${RouteConstant.dashboard.url}`])
        }
        else if(roleData?.builderView || roleData?.publishedView){
          this.router.navigate([`${RouteConstant.scripting.url}`]);
        }
        else if(roleData?.aimrView || roleData?.scheduleView || roleData?.dispatchView || roleData?.calendarView){
          this.router.navigateByUrl(`${RouteConstant.planning.url}`);
        }
        else if(roleData?.opportunityView){
          this.router.navigateByUrl(`${RouteConstant.opportunities.path}`);
        }else if(roleData?.jobView){
          this.router.navigateByUrl(`${RouteConstant.jobs.path}`);
        }else if(roleData?.customerView){
          this.router.navigateByUrl(`${RouteConstant.customers.url}/list`);
        }else if(roleData?.employeeView){
          this.router.navigateByUrl(`${RouteConstant.employee.url}/list`);
        }else if(roleData?.customerChatView){
          this.router.navigateByUrl(`${RouteConstant.customerChat.url}`);
        }else if(roleData?.feedView || roleData?.libraryView || roleData?.liveStreamView){
          this.router.navigateByUrl(`${RouteConstant.liveStream.url}`);
        }else if(roleData?.itemView){
          this.router.navigateByUrl(`${RouteConstant.catalog.url}`)
        }
        else {
          this.router.navigateByUrl(`${RouteConstant.help.url}`)
        }
      }else {
        const permissionData = adminProfile && adminProfile?.permission_data ? adminProfile?.permission_data[0]: {};
        if(permissionData?.dashboard){
          this.router.navigateByUrl(`/${RouteConstant.dashboard.path}`);
        }else if(permissionData?.scripting){
          this.router.navigateByUrl(`${RouteConstant.publish.url}`);
        }else{
          this.router.navigateByUrl(`${RouteConstant.planning.url+RouteConstant.aimr.url}`);
        }
      }
    }
    this.loader.stop();
  },(err)=> {
    this.loader.stop();
  })
  }
}