import { Injectable } from '@angular/core';
import { environment as ENV, environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LocalStorageProvider } from './storage.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ImageSliderComponent } from 'src/app/partials/dialogs/image-slider/image-slider.component';
import { CollectSignatureComponent } from 'src/app/partials/dialogs/collect-signature/collect-signature.component';
import { ApproveEstimateComponent } from 'src/app/partials/dialogs/approve-estimate/approve-estimate.component';
import { API_ROUTES } from '../_constants/api-route.constant';

@Injectable({
  providedIn: 'root'
})

export class MainService {
  public currentUser = new BehaviorSubject<any>(this.storageService.getItem('CurrentUser'));
  private activeEstimate = new Subject<any>();
  private navUpdated = new Subject<any>();
  public activeEstimate$ = this.activeEstimate.asObservable();
  dialogRef: any;
  constructor(
    private http: HttpClient,
    private loader: NgxUiLoaderService,
    private storageService: LocalStorageProvider,
    private router: Router,
    private dialog: MatDialog
  ) {
  }

  updateUser(data){
    return this.currentUser.next(data);
  }

  setActiveEstimate(data:any){
    this.activeEstimate.next(data);
  }

  getNav(){
    return this.navUpdated.asObservable();
  }

  setNav(){
    this.navUpdated.next(true);
  }

  openImageSliderModal(images:any){
    const dialogRef = this.dialog.open(ImageSliderComponent, {
      minWidth: '100%',
      height: '100vh',
      backdropClass: 'backdropBackground',
      panelClass: 'imageSlider',
      data: {
        images: images
      }
    });
  }

  collectSignature(){
    this.dialogRef = this.dialog.open(CollectSignatureComponent, {
      panelClass: 'collect-sign-dialog',
      data: {}
    });
  }

  approveSignature(data){
    console.log(data, "Dsadjlasdasdjkasjd");
    
    this.dialogRef = this.dialog.open(ApproveEstimateComponent, {
      panelClass: 'collect-sign-dialog',
      backdropClass: 'blurBackground',
      width: '100%',
      data: data
    });
  }
  public confirmed() : Observable<any> {
    return this.dialogRef.afterClosed()
  }

  get(path: string, payload: any): Observable<any> {
    let params = new HttpParams()
    params = params.appendAll(payload)
    return this.http.get(`${path}`, { params: params }).pipe(catchError(this.formatErrors));
  }
  
  delete(path: string, id:any): Observable<any> {
    return this.http.delete(`${path+'/'+id}`,).pipe(catchError(this.formatErrors));
  }

  deleteQuery(path: string): Observable<any> {
    return this.http.delete(`${path}`,).pipe(catchError(this.formatErrors));
  }

  deleteMultiParams(path: string, id:any,id2:any,id3?:any): Observable<any> {
    return this.http.delete(`${path+'/'+id+'/'+id2 + (id3 ?'/'+ id3 : '')}`).pipe(catchError(this.formatErrors));
  }

  deleteWithBody(path: string, id:any,body={}): Observable<any> {
    const options = {
      // headers: new HttpHeaders(),
      body: body
    };
    return this.http.delete(`${path+'/'+id}`,options).pipe(catchError(this.formatErrors));
  }

  deleteWithOnlyBody(path: string,body={}): Observable<any> {
    const options = {
      // headers: new HttpHeaders(),
      body: body
    };
    return this.http.delete(`${path}`,options).pipe(catchError(this.formatErrors));
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(`${path}`, JSON.stringify(body)).pipe(catchError(this.formatErrors));
  }

  patch(path: string, body: Object = {}): Observable<any> {
    return this.http.patch(`${path}`, JSON.stringify(body)).pipe(catchError(this.formatErrors));
  }

  post(path: string, body: Object = {}): Observable<any> {
    return this.http.post(`${path}`, JSON.stringify(body)).pipe(catchError(this.formatErrors));
  }

  postFile(path: string, file: File): Observable<any> {
    
    const formData: FormData = new FormData();
    formData.append('file', file);
    console.log(formData,file)
    return this.http.post(`${path}`, formData).pipe(catchError(this.formatErrors));
  }

  // delete(path): Observable<any> {
  //   return this.http.delete(`${ENV.apiUrl}${path}`).pipe(catchError(this.formatErrors));
  // }

  private formatErrors(error: any) {
    return throwError(() => error.error);
  }

  //Helper Function

  logout() {
    this.loader.start();
    this.get(API_ROUTES.LOGOUT,{}).subscribe({
      next: (res)=>{
        this.clearData();
      },error:(err)=>{
        this.clearData();
      }
    })    
  }

  clearData(){
    if(this.storageService.getItem('LoginAs') == 'SuperAdmin'){
      // const id = this.storageService.getItem('CurrentUser')._id
      window.location.href=`${environment.baseURL}`;
      this.storageService.clearAll();
      // this.dialogRef.closeAll()
      this.loader.stop();
    }else{
      this.storageService.clearAll();
      this.dialog.closeAll();
      this.loader.stop();
      this.router.navigate(['']);
    }
  }


  get isLoggedIn() {
    let token = this.storageService.getItem('token')
    if (token != undefined && token != null) {
      return true;
    }
    return false;
  }
}