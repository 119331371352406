import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CookieService } from 'ngx-cookie-service';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppHttpInterceptor } from './core/_interceptor/interceptor';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { CanLoginActivate } from './core/_guards/auth.guard';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire/';
import { MessagingService } from './core/_services/messaging.service';
import { AsyncPipe } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CallbackComponent } from './callback/callback.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#326395',
  fgsColor: '#326395',
  pbColor: '#326395',
}
@NgModule({
  declarations: [
    AppComponent,
    CallbackComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    MatSelectModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      // positionClass: 'toast-top-right',
    preventDuplicates: true,
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.googleKey,
      libraries: ['places']
    }),
    HttpClientModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    BrowserAnimationsModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularSvgIconModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    MatDialogModule
  ],
  providers: [
    CookieService,
    CanLoginActivate,
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    MessagingService,AsyncPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
