import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ZoomMtg } from '@zoomus/websdk';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { API_ROUTES } from 'src/app/core/_constants/api-route.constant';
import { RouteConstant } from 'src/app/core/_constants/route.constant';
import { MainService } from 'src/app/core/_services/main.service';
import { LocalStorageProvider } from 'src/app/core/_services/storage.service';
import { environment } from 'src/environments/environment';


ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
ZoomMtg.i18n.load('en-US');
ZoomMtg.setZoomJSLib('https://source.zoom.us/2.8.0/lib', '/av');
@Component({
  selector: 'app-join-meeting',
  templateUrl: './join-meeting.component.html',
  styleUrls: ['./join-meeting.component.scss']
})
export class JoinMeetingComponent implements OnInit, AfterViewInit {
  meetingNumber: any;
  sdkKey: string;
  role = 0;
  leaveUrl: string;
  userName: string;
  userEmail: string;
  passWord = '12345678';
  zoomCurrentUser: any;
  ZoomMtgs: any;
  constructor(private service: MainService, private storageService: LocalStorageProvider, private router: Router, private activatedRoute: ActivatedRoute, private toastr: ToastrService, private loader: NgxUiLoaderService) {
    this.leaveUrl = window.location.origin + `${RouteConstant.liveStream.url}`;
    const currentUser = this.storageService.getItem('CurrentUser')
    this.userName = currentUser?.fullName;
    this.userEmail = currentUser?.email;
    this.sdkKey = `${environment.meetingConfig.CLIENT_ID}`;
    ZoomMtg.setZoomJSLib(null, null);
    ZoomMtg.prepareWebSDK = null;
  }

  async ngOnInit(): Promise<any> {

  }

  async ngAfterViewInit() {
    const { ZoomMtg } = await import('@zoom/meetingsdk');
    this.ZoomMtgs = ZoomMtg;
    this.ZoomMtgs.setZoomJSLib('https://source.zoom.us/3.8.5/lib', '/av');
    this.ZoomMtgs.preLoadWasm();
    this.ZoomMtgs.prepareWebSDK();
    this.ZoomMtgs.i18n.load('en-US');
    this.getParams();
  }

  getParams() {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params?.['meetingId']) {
        this.meetingNumber = params?.['meetingId'];
        document.getElementById('zmmtg-root').style.display = 'block'
        this.ZoomMtgs.init({
          isSupportAV: true,
          debug: true,
          showMeetingHeader: true,
          webEndpoint: "zoom.us",
          leaveUrl: this.leaveUrl,
          disablePreview: true,
          patchJsMedia: true,
          success: (success) => {
            console.log(success)
            this.getSignature();
          },
          error: (error) => {
            window.location.reload()
            console.log(error)
          }
        })

      }
      else {
        this.router.navigate([`${RouteConstant.liveStream.url}`])
      }
    })
  }



  getSignature() {
    const data = {
      meetingNumber: this.meetingNumber,
      role: this.role
    }
    this.service.get(`${API_ROUTES.MEETING.getSignature}`, data).subscribe({
      next: (response) => {
        if (response.status) {
          console.log(response);
          this.startMeeting(response.data.signature)
        }
      },
      error: (error) => {
        this.toastr.error(error.msg)
      },
      complete: () => this.loader.stop(),
    })
  }

  startMeeting(signature) {
    this.ZoomMtgs.join({
      signature: signature,
      meetingNumber: this.meetingNumber,
      userName: this.userName,
      sdkKey: this.sdkKey,
      userEmail: this.userEmail,
      passWord: this.passWord,
      success: (success) => {
        console.log(success);
        this.ZoomMtgs.getCurrentUser({
          success: (success) => {
            this.zoomCurrentUser = success.result.currentUser
          },
          error: (error) => console.log(error)
        })
      },
      error: (error) => {
        // window.location.reload();
        console.log(error)
      }
    })
  }

  ngOnDestroy() {
    document.getElementById('zmmtg-root').style.display = 'none';
    if (this.zoomCurrentUser && this.zoomCurrentUser.isHost) {
      this.ZoomMtgs.leaveMeeting({
        success: (success) => {
          console.log(success)
          window.location.reload();
        },
        error: (error) => {
          window.location.reload();
          console.log(error)
        }
      })
    }
    // 2. Clean up the Zoom SDK root element
    const zoomRoot = document.getElementById('zmmtg-root');
    if (zoomRoot) {
      zoomRoot.style.display = 'none';
    }
    this.zoomCurrentUser = null;
    this.ZoomMtgs.setZoomJSLib(null, null);
    this.ZoomMtgs.prepareWebSDK = null;
  }
}