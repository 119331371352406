import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSignaturePadComponent } from '@mr-samani/ngx-signature-pad';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { API_ROUTES } from 'src/app/core/_constants/api-route.constant';
import { AppConst } from 'src/app/core/_constants/app.constant';
import { MainService } from 'src/app/core/_services/main.service';
import { UploadService } from 'src/app/core/_services/upload.service';

@Component({
  selector: 'app-approve-estimate',
  templateUrl: './approve-estimate.component.html',
  styleUrls: ['./approve-estimate.component.scss']
})
export class ApproveEstimateComponent implements OnInit {

  @ViewChild('signaturePad') signaturePad: any; 
  public dateFormat = AppConst.DATE_FORMAT

  initialPage: boolean = true;
  open: any;
  uploadedFiles: any;
  imageUrl: any;
  // imageInvalidMsg: any;
  // imageInvalid: boolean = false;
  signature: string = '';
  isSignatureNotAdded: boolean = true;

  constructor(public dialogRef: MatDialogRef<ApproveEstimateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private loader: NgxUiLoaderService,
    private toastr: ToastrService,
    private upload: UploadService,
    private service:MainService
  ) { }

  ngAfterViewInit(): void {
    setTimeout(()=>{
      this.setCanvasSize();
    },500)
  }

  setCanvasSize(){
    let widthDiv = document.querySelector('.signPad') 
    const canvas = this.signaturePad.canvas;
    canvas.nativeElement.width = widthDiv.clientWidth;
  }

  uncheckedEstimate: any = [];
  checkedEstimate: any =[];
  ngOnInit(): void {
    this.getDefaultStatusButton();
    this.uncheckedEstimate = this.data.allEstimate.filter((x)=>{
      return !this.data.approveEstimate.includes(x._id) && !x.isInclude && !x.noData
      // return !this.data.approveEstimate.includes(x._id) && !x.isInclude && !x.noData
    })
    console.log(this.uncheckedEstimate,"dsadsadsadsad");
    
  }
  reset() {
    this.imageUrl = ''
  }
  approveSignature() {
    if (this.imageUrl != '' && this.imageUrl != null && this.imageUrl != undefined) {
      this.dialogRef.close({imageUrl: this.imageUrl, approved: [...this.data.approveEstimate,...this.checkedEstimate]});
    }
    else {
      // this.imageInvalidMsg =  APP_CONSTANT.VALIDATION.signature;
    }
  }
  async fileupload(e: any) {
    const selectedFile = e;
    if (e) {
    this.loader.start();

      if ((selectedFile.type.match(/png/) == null)) {
        // this.imageInvalidMsg = APP_CONSTANT.VALIDATION.imageExt;
        // this.imageInvalid = true;
        this.loader.stop();
        return false;
      }
      let uploadedImage: any = await this.upload.uploadFile(selectedFile);
      if (uploadedImage) {
        this.imageUrl = uploadedImage;
        // this.imageInvalidMsg = "";
        // this.imageInvalid = false;
        this.loader.stop();
        this.approveSignature();
        return true;
      } else {
        this.loader.stop();
        this.toastr.error(`${AppConst.ERROR_MESSAGES.SERVER_ERROR}`)
        return false;
      }
    }
    this.loader.stop()
    return false;

  }

  getSignature(ev: string) {
    this.isSignatureNotAdded = this.isCanvasBlank(this.signaturePad.ctx)
  }

  clear(pad: NgxSignaturePadComponent) {
    pad.clearSignaturePad();
    this.isSignatureNotAdded = this.isCanvasBlank(this.signaturePad.ctx)
    this.signature = '';
    this.imageUrl = '';
  }

  isCanvasBlank(canvas: any) {
    const context = canvas;
    const pixelBuffer = new Uint32Array(
      context.getImageData(0, 0, 1440, 240).data.buffer
    );
  
     return (!pixelBuffer.some(color => color !== 0));
  }

  getFile(pad: NgxSignaturePadComponent) {
    this.fileupload(pad.getSignatureFile());
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setCanvasSize()
  }

 
  estimateChecked(id: any){
    let index = this.checkedEstimate.indexOf(id);
    if(index == -1){
      this.checkedEstimate.push(id)
    }
    else{
      this.checkedEstimate.splice(index,1)
    }
  }

  changeApproved(){
      this.initialPage = false;
      setTimeout(()=>{
        this.setCanvasSize();
      },500)
      this.uncheckedEstimate = this.data.allEstimate.filter((x)=>{
        return this.data.approveEstimate.includes(x._id) || this.checkedEstimate.includes(x._id)
      })
  }

   

  calculateAmount(key: any) {
    let total = 0.00;
    this.data.allEstimate.map((order: any) => {
        if(order._id && (this.data.approveEstimate.includes(order._id) || this.checkedEstimate.includes(order._id))){
           total = total + parseFloat(order[key]);
          }
      });
    return Number(total.toFixed(2));
  }

  inspectionBtn:any;
  getDefaultStatusButton(){
    this.service.get(`${API_ROUTES.SETTING.getInspectionBtnStatus}`,{}).pipe().subscribe(response => {
      this.loader.start();
      if (response.status) {
        this.inspectionBtn = response.data?.list;
        console.log(this.inspectionBtn,'initial')
        
      }
      
      this.loader.stop()
    })
  }
  setInspectionStatus(id: number): string {
    console.log(id);
    const foundBtn = this.inspectionBtn?.find(ele => ele?._id === id);
    if (foundBtn) {
        // Apply class based on originalName
        switch (foundBtn.originalName) {
            case 'Fail':
                return 'red-btn';
            case 'Pass':
                return 'pass-status';
            case 'N/A':
                return 'null-status';
            default:
                return ''; 
        }
    } else {
        return ''; // Default class if no button is found
    }
}
}
