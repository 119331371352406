<div class="modal-header p-0">
    <button class="button-close base-btn" mat-button [mat-dialog-close]="false">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" />
        </svg>
    </button>
</div>
<div class="modal-body p-0">
    <p class="pt-0 include" *ngIf="initialPage && uncheckedEstimate.length>0">Do you want to include this item?</p>
    <div class="table" *ngIf="uncheckedEstimate.length>0">
        <table>
            <thead class="p-2">
                <tr>
                    <th>S.no</th>
                    <th>SKU</th>
                    <th>Image</th>
                    <th>Item Name</th>
                    <th>Description</th>
                    <th>Video</th>
                    <th>Script</th>
                    <th>Charge Type</th>
                    <th>Price</th>
                    <th>Tax</th>
                    <th>Qty</th>
                    <th>Total</th>
                    <th>Inspection</th>
                    <th>Include</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let estimate of uncheckedEstimate;let i=index" class="example-box">
                    <ng-container *ngIf="!estimate.noData">
                        <td class="index-column">{{i+1}}</td>
                        <td class="sku-column">{{estimate.sku}}</td>
                        <td class="media-column">
                            <div *ngIf="estimate?.images?.length >1 ; else showSingleImage" class="more-images ">
                                <span class="rounded-circle">{{'+'+ (estimate.images.length-1)}}</span>
                                <div class="image">
                                    <img [src]="estimate.images[0].link" width="28px" height="28px"
                                        class="rounded-circle">
                                </div>
                            </div>
                            <ng-template #showSingleImage>
                                <img *ngIf="estimate?.images?.length else noMedia" [src]="estimate.images[0].link"
                                    width="28px" height="28px" class="rounded-circle">
                                <ng-template #noMedia>
                                    <div>---</div>
                                </ng-template>
                            </ng-template>
                        </td>
                        <td>{{estimate['itemName']}}</td>
                        <td class="description">
                            <div>{{estimate['description']}}</div>
                        </td>
                        <td>
                            <div *ngIf="estimate?.videos?.length >1 ; else showSingleVideo" class="more-images ">
                                <span class="rounded-circle">{{'+'+ (estimate.videos.length-1)}}</span>
                                <div class="image">
                                    <video [src]="estimate.videos[0].link" width="28px" height="28px"
                                        class="rounded-circle"></video>
                                </div>
                            </div>
                            <ng-template #showSingleVideo>
                                <video *ngIf="estimate?.videos?.length else noMedia" [src]="estimate.videos[0].link"
                                    width="28px" height="28px" class="rounded-circle"></video>
                                <ng-template #noMedia>
                                    <div>---</div>
                                </ng-template>
                            </ng-template>
                        </td>
                        <td class="description">
                            <div>{{estimate['script']}}</div>
                        </td>
                        <td>{{estimate['chargeType']}}</td>
                        <td>{{estimate['price']? '$'+(estimate['price'].toFixed(2)) : '$0.00' }}</td>
                        <!-- <td>{{estimate['tax']? '$'+(estimate['tax']?.toFixed(2)) : '$0.00'}}</td> -->
                        <td>{{estimate['tax']? '$'+(estimate['tax']) : '$0.00'}}</td>
                        <td>{{estimate['quantity']}}</td>
                        <td>{{estimate['total']? '$'+(estimate['total'].toFixed(2)) : '$0.00'}}</td>
                        <td><span class="btn w-100"
                                [ngClass]="setInspectionStatus(estimate.inspectionId)">{{estimate.inspectionStatus ||
                                '---'}}</span></td>
                        <td *ngIf="initialPage"><input class="form-check-input" type="checkbox"
                                [checked]="checkedEstimate.includes(estimate?._id)"
                                (change)="estimateChecked(estimate?._id)" /></td>
                        <td *ngIf="!initialPage"><input class="form-check-input" type="checkbox" checked disabled />
                        </td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="w-100">
        <hr class="dashed-line" *ngIf="uncheckedEstimate.length>0" />
        <div class="py-3">
            <div class="d-flex justify-content-between align-items-center amount">
                <h3 class="amount-heading pb-0 mb-0">SubTotal</h3>
                <span class="amount-heading">{{'$'+ (calculateAmount('total') -
                    calculateAmount('tax')).toFixed(2)}}</span>
            </div>
            <div class="d-flex justify-content-between align-items-center amount">
                <h3 class="amount-breakup pb-0 mb-0">Tax</h3>
                <span class="amount-breakup">{{'$'+ calculateAmount('tax')}}</span>
            </div>
            <div class="d-flex justify-content-between align-items-center amount">
                <h3 class="amount-breakup pb-0 mb-0">Total</h3>
                <span class="amount-breakup">{{'$'+ calculateAmount('total')}}</span>
            </div>
        </div>
    </div>

    <div class="signature" *ngIf="(initialPage && !checkedEstimate.length) || !initialPage">
        <p class="pt-0"> Collect Signature</p>
        <form>
            <div class="form-group text-start ">
                <label class="mb-3">Draw Here</label><br>
                <div class="signPad">
                    <ngx-signature-pad #signaturePad class="display-sign-pad" (signature)="getSignature($event)"
                        [containerStyle]="'margin:0px auto;cursor:pointer;display:block;border-radius:9px;border: 1px solid #E8E8E8;height:240px'"
                        [height]="240" [lineStroke]="4" [lineColor]="'#IDEIDE'"></ngx-signature-pad>
                </div>
            </div>
        </form>
    </div>
    <div class="footerSec">
        <button type="button" class="btn base-btn cancel" [mat-dialog-close]="false">Cancel</button>
        <button type="button" class="btn base-btn reset me-4 display-sign-pad"
            *ngIf="(initialPage && !checkedEstimate.length) || !initialPage"
            (click)="clear(signaturePad)">Reset</button>
        <button type="submit" [disabled]='isSignatureNotAdded'
            *ngIf="(initialPage && !checkedEstimate.length) || !initialPage"
            class="btn base-btn submit display-sign-pad" (click)="getFile(signaturePad)">Confirm Approval</button>
        <button type="submit" *ngIf="initialPage && checkedEstimate.length" class="btn base-btn submit"
            (click)="changeApproved()">Proceed</button>
    </div>
</div>