import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { API_ROUTES } from '../core/_constants/api-route.constant';
import { RouteConstant } from '../core/_constants/route.constant';
import { MainService } from '../core/_services/main.service';
import { LocalStorageProvider } from '../core/_services/storage.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {

  constructor(private service: MainService, private storageService: LocalStorageProvider, private loader: NgxUiLoaderService, private toastr: ToastrService, private router: Router ) { }

  ngOnInit(): void {
    
    this.submit()
  }

  submit() {
    
      let id = this.storageService.getItem('clientId')
      let secret = this.storageService.getItem('clientSecret')
      if(id && secret){
        this.loader.start();
        this.service.get(`${API_ROUTES.ITEMS.callback}`, {url: window.location.href, clientId: id, clientSecret: secret}).pipe().subscribe(response => {
          this.loader.stop();
          if (response.status) {
            
            this.toastr.success(response.msg)
            this.storageService.clearItem('clientId')
            this.storageService.clearItem('clientSecret')
            this.router.navigate([RouteConstant.quickBook.url])
            // window.location.href = response.data;
            let currentUser = this.storageService.getItem('CurrentUser');
            currentUser.isQbSync = true;
            this.storageService.setItem('CurrentUser', currentUser);
          }
        }, err => {
          this.toastr.error(err.msg)
          this.storageService.clearItem('clientId')
          this.storageService.clearItem('clientSecret')
          this.loader.stop();
          this.router.navigate([RouteConstant.quickBook.url])
        })
      }
      else{
        this.router.navigate([RouteConstant.quickBook.url])
      }
    
  }


}
